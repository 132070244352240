// libraries
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createContext, useState } from "react";


//pages && components
import Header from "./Components/layouts/Header/Header";
import Footer from "./Components/layouts/Footer";
import MainLayout from "./Components/layouts/MainLayout";
import Legacy from "./Pages/Legacy";
import NotFound from "./Pages/NotFound";

// CSS 
import "./css/style.css";
import ToggleThemeButton from "./Components/buttons/ToggleThemeButton";
import Gallery from "./Pages/Gallery";


// contexts
export const ThemeContext = createContext('light');
export const SideBarContext = createContext('close');

function App() {

  const [darkMode , setDarkMode] = useState(localStorage.getItem("DARK-MODE"))
  const [theme, setTheme] = useState("light");
  const [sidebar, setSidebar] =useState("close");
  const toggleTheme = () => {
    setTheme((curr) => (curr ==="light" ? "dark" : "light"));
  }
  const toggleSideBar = () => {
    setSidebar((curr) => (curr ==="close" ? "open" : "close"));
  }

  return (
    <ThemeContext.Provider value={{theme, toggleTheme}}>
      <SideBarContext.Provider value={{sidebar, toggleSideBar}}>
      <div className="App" id={theme}>
        <BrowserRouter>
        <ToggleThemeButton />
          <Header size="regular" id={sidebar}/>
          <span id="home_target"></span>
          <Routes>
            <Route path="/" element={<MainLayout />} />
            <Route path="/mentions-legales" element={<Legacy />} />
            <Route path="/gallerie" element={<Gallery />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
      </SideBarContext.Provider>
    </ThemeContext.Provider>
  );
}

export default App;

import {Player} from "video-react";

const VideoPlayer = (props) => {
    const source = props.src;
    return <div className="video-player">
        <Player
        playsInline
        src={source}/>
    </div>;
}


export default VideoPlayer;
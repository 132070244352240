import React from 'react'
import { images_list, video_list } from '../Assets/data/gallery_data'
import VideoPlayer from "../Components/others/VideoPlayer";
const Gallery = () => {
  return (
    <section className='page gallery' id="gallery">
      <h3>La médiation en image</h3>
      {
        video_list.map((el)=>(
            <VideoPlayer key={el.id} src={el.src}/>
        ))
      }
    </section>
  )
}

export default Gallery

const logo_spec = [
    {
    special_day: "tsa",
    month: 4,
    day:2,
    logo_src: "tsa",
},

{
    special_day: "tdah",
    month: 6,
    day:12,
    logo_src: "tdah",
},

{
    special_day: "trisomie-21",
    month: 3,
    day:21,
    logo_src: "trisomie-21",
},
{
    special_day: "alz",
    month: 8,
    day:21,
    logo_src: "alzh",
},

{
    special_day: "oct-rose",
    month: 10,
    day:0,
    logo_src: "oct",
}
]


export {
    logo_spec
}
import { logo_spec } from "../Assets/data/logo_spec";

export function changeLogo(logo_color) {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let logo = `./logo-${logo_color}_silhouette.png`
    logo_spec.forEach((el) => {
        
        if (el.month === month && (el.day === 0 || el.day === day)) {
            logo = `./logo-${logo_color}_silhouette-${el.logo_src}.png`;
        }
    })
    console.log(logo)
    return logo;
}
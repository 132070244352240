
import { Link } from "react-scroll";

import { changeLogo } from "../../../Utils/logo";


const Logo = (props) => {
  
  const logo_src = changeLogo(props.color);

  return (
    <div className='logo-container'>
      <Link className="link-to-top" to="home_target" spy={true} smooth={true}>
        <img src={logo_src} alt="logo" className="logo" />
      </Link>
      <span className="brand-name">Des patounes pour un sourire</span>
    </div>
  )
}

export default Logo;